/* =========================================================================================
  File Name: user.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import state from './state.js'
import actions from './actions.js'
import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations: {
    SET_USER (state, user) {
      state.user = user
    },
    SET_BANNERS (state, banners) {
      state.banners = banners
    },
    SET_SITES (state, sites) {
      state.sites = sites
    },
    SET_SITES_CREDIT (state, data) {
      state.sites_credit = data
    },
    SET_CREDIT_INCREASE (state, data) {
      state.creditincrese = data
    },
    SET_CREDIT_DECREASE (state, data) {
      state.creditdecrese = data
    },
    SET_LAST_BONUS (state, bonus) {
      state.lastbonus = bonus
    },
    SET_ALERT_WITHDRAWALL (state, data) {
      state.alertwithdraw = data
    },
    SET_PROMOTION (state, data) {
      state.promotion = data
    },
    SET_PROMOTIONS (state, data) {
      state.promotions = data
    },
    SET_GOT_PRO (state, data) {
      state.gotpro = data
    },
    SET_LOADING (state, data) {
      state.loading = data
    },
    SET_GAEMS (state, data) {
      state.games = data
    },
    SET_HOTGAMES (state, data) {
      state.hotGames = data
    },
    SET_NEWGAMES (state, data) {
      state.newGames = data
    },
    SET_SLOTBRANDS (state, data) {
      state.slotbrands = data
    },
    SET_CASINOGAMES (state, data) {
      state.casinoGames = data
    },
    SET_PLAYGAME (state, data) {
      state.urlplaygame = data
    },
    SET_MINIGAMES (state, data) {
      state.minigames = data
    },
    SET_FISHGAMES (state, data) {
      state.fishgames = data
    },
    SET_FAVORITEGAMES (state, data) {
      state.favoritegames = data
    },
    SET_GAMES (state, data) {
      state.games = data
    },
    SET_CARDGAMES (state, data) {
      state.cardGames = data
    },
    SET_REWARDS (state, data) {
      state.rewards = data
    },
    SET_ALLGAMES (state, data) {
      state.allgames = data
    },
    updateField
  },
  actions,
  getters: {
    getField
  }
}
